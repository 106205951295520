const DEFAULT_GAME_PERMISSIONS = {
	beauty: {
		visible: false,
		usable: false,
	},
	coin: {
		visible: true,
		usable: false,
	},
	crt: {
		visible: true,
		usable: false,
	},
	dice: {
		visible: false,
		usable: false,
	},
	dictator: {
		visible: true,
		usable: false,
	},
	dictatorreverse: {
		visible: false,
		usable: false,
	},
	dishonestyaltruistic: {
		visible: false,
		usable: false,
	},
	dishonestygroup: {
		visible: false,
		usable: false,
	},
	dishonestyindividual: {
		visible: false,
		usable: false,
	},
	financialdecision: {
		visible: false,
		usable: false,
	},
	goldenballs: {
		visible: false,
		usable: false,
	},
	negative: {
		visible: false,
		usable: false,
	},
	positive: {
		visible: false,
		usable: false,
	},
	questions: {
		visible: true,
		usable: true,
	},
	raven: {
		visible: false,
		usable: false,
	},
	surveyemotions: {
		visible: false,
		usable: false,
	},
	taxevasion: {
		visible: false,
		usable: false,
	},
	taxmorale: {
		visible: false,
		usable: false,
	},
	ultimatumreceiver: {
		visible: false,
		usable: false,
	},
	ultimatumsender: {
		visible: false,
		usable: false,
	},
	videos: {
		visible: false,
		usable: false,
	},
}

export default DEFAULT_GAME_PERMISSIONS;