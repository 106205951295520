import React from 'react';
import {
	Box,
	Button,
	LinearProgress,
	FormControlLabel,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import {
	TextField,
	Switch,
  } from 'formik-mui';
import { navigate } from "gatsby"
import Layout from './layout';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth, db } from '../../firebase-info';
import { doc, setDoc, Timestamp } from "firebase/firestore";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import DEFAULT_GAME_PERMISSIONS from '../utils/defaultGamePermissions';

const formContainer = {
	width: '100%',
	marginTop: '32px',
};
const inputWidth = {
	minWidth: '80%'
};

const BeRegisterForm = () => {
	const { t } = useTranslation();
	const handleSubmit = async (values) => {
		try {
			const user = await createUserWithEmailAndPassword(auth, values.email, values.password);
			
			updateProfile(user.user, { displayName: `${values.name} ${values.surname}` } );

			const now = new Date();

			await setDoc(doc(db, "users", user.user.uid), {
				organization: values.orgName,
				gamePermissions: DEFAULT_GAME_PERMISSIONS || {},
				generalPermissions: {
					canDownload: false,
				},
				email: values.email,
				name: values.name,
				surname: values.surname,
				isAdminValidated: false,
				role: 'researcher',
				creationDate: Timestamp.fromDate(now)
			});

			navigate('/dashboard/');
		} catch(e) {
			const errorCode = e.code;
			//@TODO add proper messages & reset password functionality
			const errorMessage = errorCode === 'auth/email-already-in-use' ? 'El usuario ya existe, si ha olvidado su contraseña, por favor, póngase en contacto con nosotros.' : 'Ha ocurrido un problema, por favor, inténtelo más tarde.';
		}
	}

	const validate = values => {
		const errors = {};
	 
		if (!values.email) {
			errors.email = t('Required');
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			errors.email = t('Invalid email address');
		}

		if(!values.repeatEmail) {
			errors.repeatEmail = t('Required');
		} else if (values.email !== values.repeatEmail) {
			errors.repeatEmail = t('Emails must be the same');
		}

		if (!values.orgName) {
		  errors.orgName = t('Required');
		}

		if (!values.name) {
			errors.name = t('Required');
		  }

		  if (!values.surname) {
			errors.surname = t('Required');
		  }

		if (!values.password) {
		  errors.password = t('Required');
		} else if (values.password.length < 6) {
		  errors.password = t('Must have 6 characters or more');
		}

		if (!values.repeatPassword) {
			errors.repeatPassword = t('Required');
		} else if(values.password !== values.repeatPassword) {
			errors.repeatPassword = t('Passwords must be the same');
		}

		if(!values.legalCheck) {
			errors.legalCheck = t('You must agree on the legal terms');
		}

		return errors;
	  };

	return(
		<Layout>
			<p><Trans>Institutional Email</Trans></p>
			<Formik
				initialValues={{
					email: '',
					repeatEmail: '',
					orgName: '',
					name: '',
					surname: '',
					password: '',
					repeatPassword: '',
					legalCheck: false,
					
				}}
				style={formContainer}
				onSubmit={(values) => { handleSubmit(values)} }
				validate={(values) => { return validate(values) }}
			>
				{({values, submitForm, resetForm, isSubmitting, touched, errors}) => (
					<Form>
						<Box margin={1}>
							<Field
								component={TextField}
								style={inputWidth}
								name="email"
								type="email"
								label={t("Institutional Email")}
							/>
						</Box>
						<Box margin={1}>
							<Field
								component={TextField}
								style={inputWidth}
								name="repeatEmail"
								type="email"
								label={t("Repeat Email")}
							/>
						</Box>
						<Box margin={1}>
							<Field
								component={TextField}
								style={inputWidth}
								type="password"
								label={t("Password")}
								name="password"
							/>
						</Box>
						<Box margin={1}>
							<Field
								component={TextField}
								style={inputWidth}
								type="password"
								label={t("Repeat Password")}
								name="repeatPassword"
							/>
						</Box>
						<Box margin={1}>
							<Field
								component={TextField}
								style={inputWidth}
								name="orgName"
								type="text"
								label={t("Organization name")}
							/>
						</Box>
						<Box margin={1}>
							<Field
								component={TextField}
								style={inputWidth}
								name="name"
								type="text"
								label={t("Name")}
							/>
						</Box>
						<Box margin={1}>
							<Field
								component={TextField}
								style={inputWidth}
								name="surname"
								type="text"
								label={t("Surname")}
							/>
						</Box>
						<Box margin={1}>
							<FormControlLabel
								control={
									<Field component={Switch} type="checkbox" name="legalCheck" />
								}
								label={<p><Trans>You must agree on the</Trans> <a href={'https://firebasestorage.googleapis.com/v0/b/premiumandpremium.appspot.com/o/documents%2FprivateDocuments%2FInformacion%20sobre%20el%20Uso%20de%20Cookies%20P%26P.pdf?alt=media&token=82f5eb74-c1b1-4606-846b-d89070e6bfc1'}><Trans>legal terms</Trans></a></p>}
							/>
						</Box>
						{isSubmitting && <LinearProgress />}
						<Box margin={1}>
							<Button
								sx={{margin: 1}}
								variant="contained"
								color="secondary"
								size="large"
								disabled={isSubmitting}
								onClick={() => {
									resetForm();
								}}
							>
								<Trans>Reset values</Trans>
							</Button>
							<Button
								sx={{margin: 1}}
								variant="contained"
								size="large"
								color="primary"
								disabled={isSubmitting || !values.legalCheck}
								onClick={submitForm}
							>
								<Trans>Register</Trans>
							</Button>
						</Box>
					</Form>
				)}
			</Formik>
		</Layout>
	);
}

export default BeRegisterForm;