import * as React from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from '@mui/material/styles';
import { 
	Box,
	Grid,
	Typography,
} from '@mui/material';
import { auth } from '../../firebase-info';
import { navigate } from "gatsby";
import BeRegisterForm from "../components/be-register-form.jsx";
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';
import LogoComplete from '../images/logo_complete.png';
import theme from '../styles/theme';

// styles
const pageStyles = {
	fontFamily: 'Noto Sans',
};
const topBar = {
	height: '56px',
	backgroundColor: '#eee',
}
const imageContainer = {
	height: '36.7px',
}
const headerMargin = {
	marginBottom: '20px',
	marginLeft: '16px',
	display: 'inline-block',
	verticalAlign: 'middle',
}
const verticalPadding40 = {
	paddingTop: '40px',
	paddingBottom: '40px',
}
const formContainer = {
	paddingLeft: '55px',
	paddingRight: '55px',
	borderRadius: '12px',
	minWidth: '80%',
	textAlign: 'center',
}


// markup
const RegisterPage = () => {
	async function checkLogin() {
		if (auth.currentUser !== null) {
		   navigate("/dashboard/");
		   return;
		}
	};
  
	React.useEffect(() => {
	  checkLogin();
	}, []);

	const { t } = useTranslation();

	return (
		<ThemeProvider theme={theme}>
			<main>
				<Box style={pageStyles}>
					<Box>
						<Grid container spacing={2} style={topBar}>
							<Grid item xs={12}>
								<Link to="/">
									<img
										alt="Behavioral Experiments logo"
										style={imageContainer}
										src={LogoComplete}
									/>
								</Link>
								<Box style={headerMargin}>
									<Typography fontFamily={"Noto Sans"} color={"#000000"} display={"inline"}>{t ("Researchers Sign up")}</Typography>
								</Box>
							</Grid>
						</Grid>
						<Grid style={verticalPadding40} justifyContent={"center"} container spacing={2}>
							<Grid item style={formContainer} xs={6}>
								<ThemeProvider theme={theme}>
									<BeRegisterForm/>
								</ThemeProvider>
							</Grid>
						</Grid>
					</Box>
				</Box>

			</main>
		</ThemeProvider>
	);
};

export default RegisterPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "register", "form_errors",]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
